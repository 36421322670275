<template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col cols="12">
        <v-card class="border-radius-xl card-shadow">
          <div class="px-4 py-4 mt-2">
            <v-card class="border-radius-xl">
              <v-row>
                <v-col lg="6">
                  <div class="mb-10">
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Борлуулалт
                    </h5>
                    <p class="text-sm text-body mb-0">Борлуулалтын мэдээллийг энд удирдана.</p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  right
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="d-flex flex-row"
                      style="border: 1px solid black; border-radius: 12px"
                    >
                      <v-text-field
                        class="ma-0 mb-n7"
                        style="max-width: 100px; border-radius: 12px"
                        v-model="date"
                        readonly
                        flat
                        solo
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                      <v-icon class="mx-2" size="21">mdi-arrow-right</v-icon>
                      <v-text-field
                        class="ma-0 mb-n7"
                        style="max-width: 100px; border-radius: 12px"
                        v-model="date2"
                        readonly
                        flat
                        solo
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    scrollable
                    color="bg-gradient-success"
                  >
                  </v-date-picker>
                  <v-date-picker :max="max"
                    v-model="date2"
                    no-title
                    scrollable
                    color="bg-gradient-success"
                  >
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn
                  @click="setTime(1)"
                  class="shadow border-radius-md bg-gradient-success mr-2"
                  dark
                  >1M</v-btn
                >
                <v-btn
                  @click="setTime(3)"
                  class="shadow border-radius-md bg-gradient-success mr-2"
                  dark
                  >3M</v-btn
                >
                <v-btn
                  @click="setTime(6)"
                  class="shadow border-radius-md bg-gradient-success mr-2"
                  dark
                  >6M</v-btn
                >
                <v-btn
                  @click="setYear()"
                  class="shadow border-radius-md bg-gradient-success"
                  dark
                  >1Y</v-btn
                >
              </v-row>
            </v-card>
            <v-data-table
              style="overflow-y: auto"
              v-if="purchases"
              class="mt-5"
              :items="purchases"
              :headers="purchasesHeaders"
            >
              <template v-slot:footer>
                <v-row class="mx-0 px-3 py-5">
                  <span
                    class="font-weight-bold shadow border-radius-md bg-gradient-success pa-2 white--text"
                    >Нийт борлуулалт:
                    <span class="font-weight-normal py-2"
                      >₮{{ new Intl.NumberFormat().format(totalPrice) }}</span
                    >
                  </span>
                  <span class="ml-3 font-weight-bold py-2"
                    >Нийт борлуулалтын тоо:
                    <span class="font-weight-normal">{{
                      totalQuantity
                    }}</span></span
                  >
                </v-row>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  computed: {
    ...sync("*"),
  },
  data() {
    return {
      date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000 - 86400000
      )
        .toISOString()
        .substr(0, 10),
      max: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFilter: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000 - 86400000
      ),
      dateFilter2: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      ),
      menu: false,
      purchases: null,
      totalPrice: null,
      totalQuantity: null,
      purchasesHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "Үйлчлүүлэгч Name",
          align: "start",
          sortable: true,
          value: "buyerName",
          width: "12%",
        },
        {
          text: "Үйлчлүүлэгч ID",
          align: "start",
          sortable: true,
          value: "buyerId",
          width: "5%",
        },
        {
          text: "Үнэ",
          align: "start",
          sortable: true,
          value: "totalPrice",
          width: "5%",
        },
        {
          text: "QTY",
          align: "start",
          sortable: true,
          value: "totalQTY",
          width: "7%",
        },
        {
          text: "Борлуулагч Id",
          align: "start",
          sortable: true,
          value: "soldBy.id",
          width: "5%",
        },
        {
          text: "Борлуулагч",
          align: "start",
          sortable: true,
          value: "soldName",
          width: "8%",
        },
        {
          text: "Бүтээгдэxүүн",
          align: "start",
          sortable: true,
          value: "purchasedProducts",
        },
        {
          text: "Огноо",
          align: "start",
          sortable: true,
          value: "purchasedAt",
          width: "11%",
        },
      ],
    };
  },
  watch: {
    date(val) {
      this.dateFilter = new Date(val);
      this.menu = false;
      this.setup();
    },
    date2(val) {
      this.dateFilter2 = new Date(val);
      this.menu = false;
      this.setup();
    },
  },
  methods: {
    setup() {
      this.userData.school.ref
        .collection("_allCookingPurchases")
        .where("purchasedAt", ">=", this.dateFilter)
        .where("purchasedAt", "<=", this.dateFilter2)
        .limit(50)
        .onSnapshot((purchases) => {
          this.purchases = [];
          var counter = 1;
          this.totalPrice = 0;
          this.totalQuantity = 0;
          purchases.forEach((purchase) => {
            let order = purchase.data();
            order.id = purchase.id;
            order.ref = purchase.ref;
            order.index = counter;
            order.purchasedProducts = null;
            var pcounter = 1;
            if (order.products)
              order.products.forEach((product) => {
                console.log(product.name, order.products.length);
                if (order.products.length == 1) {
                  order.purchasedProducts = product.name + " x" + product.qty;
                } else if (pcounter == 1) {
                  order.purchasedProducts = product.qty + "ш " + product.name;
                } else {
                  order.purchasedProducts =
                    order.purchasedProducts +
                    ", " +
                    product.qty +
                    "ш " +
                    product.name;
                }
                pcounter++;
              });
            console.log(order.purchasedProducts);
            order.purchasedAt =
              order.purchasedAt.toDate().getFullYear() +
              "-" +
              order.purchasedAt.toDate().getMonth() +
              "-" +
              order.purchasedAt.toDate().getDay() +
              " " +
              order.purchasedAt.toDate().getHours() +
              ":" +
              order.purchasedAt.toDate().getMinutes();

            order.buyerName = order.buyerFirstName + " " + order.buyerLastName;
            if (order.soldLastName)
              order.soldName = order.soldFirstName + " " + order.soldLastName;
            else order.soldName = order.soldFirstName;
            counter++;
            this.totalPrice = this.totalPrice + order.totalPrice;
            this.totalQuantity = this.totalQuantity + order.totalQTY;
            this.purchases.push(order);
          });
        });
    },
    setTime(subtract) {
      var oldMonth = new Date(this.date).getMonth() + 1;
      var newMonth = oldMonth - subtract;
      var newTime = this.date.split("-");
      if (newMonth < 1) {
        newMonth = 12 + newMonth;
        newTime[0] = new Date(this.date).getFullYear() - 1;
      }
      this.date = newTime[0] + "-" + newMonth + "-" + newTime[2];
    },
    setYear() {
      var newTime = this.date.split("-");
      newTime[0] = new Date(this.date).getFullYear() - 1;
      this.date = newTime[0] + "-" + newTime[1] + "-" + newTime[2];
    },
  },
  created() {
    this.setup();
  },
};
</script>
<style></style>
